import React, { useState, useEffect } from "react"
import { Section, Columns } from "../components/Containers"
import { graphql } from "gatsby"
import { Text, Image } from "../components/Core"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from "react-slick"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons/faChevronLeft"
import { faChevronRight } from "@fortawesome/pro-light-svg-icons/faChevronRight"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import Dialog from "../components/Modals/Dialog"

const settings = {
  infinite: false,
  centerPadding: "60px",
  slidesToShow: 3,
  speed: 500,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        centerPadding: "30px"
      }
    }
  ]
}

function SampleNextArrow(props) {
  const { onClick } = props
  return (
    <div className="chevron-arrow chevron-right" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronRight} style={{ zIndex: 1 }} />
    </div>
  )
}

function SamplePrevArrow(props) {
  const { onClick } = props
  return (
    <div className="chevron-arrow chevron-left" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronLeft} style={{ zIndex: 1 }} />
    </div>
  )
}

const AboutThePractice = ({ pageContext, data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [formLoaded, setFormLoaded] = useState(false)
  const post = data.allAboutThePracticeJson.nodes[0]
  const { metaTitle, metaDescription, language, url } = pageContext
  const heading = data.allAboutThePracticeJson.nodes[0].heading

  const [previewImage, setPreviewImage] = useState(null)

  useEffect(() => {
    const formBtn = document.querySelector("#form-modal")
    if (!formBtn) return
    formBtn.addEventListener("click", handleModal)

    return () => {
      formBtn.removeEventListener("click", handleModal)
    }
  }, [])

  const handleModal = () => {
    setIsModalOpen(true)
  }

  const openPreview = image => {
    if (
      pageContext.title ===
      "referring-doctors/dental-business-study-club/what-is-the-levin-dental-business-study-club/"
    ) {
      setPreviewImage(image)
    }
  }

  const closePreview = () => {
    setPreviewImage(null)
  }

  return (
    <Layout language={language} className="about-the-practice">
      <SEO
        title={metaTitle}
        description={metaDescription}
        lang={language}
        pathname={url}
      />
      <Section zeroTop className="about-the-practice__hero">
        <Columns sideColumnsSize={2}>
          <div className="column">
            <Text as="h1" text={heading} className="mb-2 px-40--mobile" />
            <Image
              wrapperClassName="is-hidden-touch"
              publicId={post.desktopHeroImage}
            />
            <div className="is-hidden-desktop">
              <Image publicId={post.mobileHeroImage} />
            </div>
          </div>
        </Columns>
      </Section>
      {post.bodySections && (
        <Section>
          <Columns sideColumnsSize={2}>
            <div className="column">
              {post.bodySections?.map((item, index) => (
                <React.Fragment key={index}>
                  <Text
                    text={item.string}
                    className="about-the-practice__text-section px-40--mobile"
                  />
                  {pageContext.title ===
                    "meet-us/board-certified-anesthesiologist/" &&
                  index === 0 ? (
                    <table className="mt-2 mb-2 px-40--mobile">
                      <thead>
                        <tr>
                          <th>Method of Anesthesia</th>
                          <th>Description of Technique</th>
                          <th>Usual Indications</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Local Anesthetic</td>
                          <td>
                            The patient remains conscious throughout the
                            procedure. A local anesthetic (e.g. lidocaine) is
                            administered in the area where the surgery is to be
                            performed. Local anesthetic is used in conjunction
                            with the other methods of anesthesia in all oral
                            surgery procedures.
                          </td>
                          <td>
                            Simple oral surgery procedures such as minor soft
                            tissue procedures and simple tooth extractions.
                          </td>
                        </tr>
                        <tr>
                          <td>Nitrous Oxide Sedation with Local Anesthetic</td>
                          <td>
                            A mixture of nitrous oxide (laughing gas) and oxygen
                            is administered through a nasal breathing apparatus.
                            The patient remains conscious and relaxed. Nitrous
                            oxide has a sedative and analgesic
                            (pain-controlling) effect.
                          </td>
                          <td>
                            Simple oral surgery procedures to more involved
                            procedures, such as removing wisdom teeth and
                            placing dental implants.
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Office and Surgery Center-Based General Anesthesia
                            with Local Anesthetic*
                          </td>
                          <td>
                            Medications are administered through an intravenous
                            line (I.V.) or by inhalation of anesthetic gases.
                            The patient falls asleep and is completely unaware
                            of the procedure being performed. Medications most
                            commonly used in I.V. form are Fentanyl (opiate),
                            Versed (benzodiazepine), Ketamine, and Diprivan.
                            Supplemental oxygen is delivered through a nasal
                            breathing apparatus or endotracheal tube. The
                            patient’s vital signs are closely monitored.
                          </td>
                          <td>
                            General anesthesia is available for all types of
                            oral and maxillo-facial surgery. A patient may
                            choose general anesthesia for simple procedures
                            depending on their level of anxiety. Most people
                            having their wisdom teeth removed or having a dental
                            implant placed will choose general anesthesia.
                            General anesthesia may be necessary if local
                            anesthesia fails to anesthetize the surgical site,
                            which often occurs in the presence of infection.
                          </td>
                        </tr>
                        <tr>
                          <td>Hospital Based General Anesthesia</td>
                          <td>
                            A patient is admitted to a hospital where anesthesia
                            is administered by the hospital staff
                            anesthesiologist.
                          </td>
                          <td>
                            Indicated for patients undergoing extensive
                            procedures such as face or jaw reconstruction and
                            TMJ surgery. General anesthesia frequently requires
                            in-hospital convalescence. Also indicated for
                            patients with medical conditions such as heart
                            disease or lung disease who require general
                            anesthesia.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              ))}
            </div>
          </Columns>
        </Section>
      )}
      {post.imageGallery && (
        <Section className="gallery-cards">
          <Columns
            sideColumnsSize={
              pageContext.title !== "meet-us/office-tour/" ? 4 : 0
            }>
            <div className="column gallery-cards__card-section">
              <Slider {...settings}>
                {post.imageGallery.map((card, i) => (
                  <div
                    className="gallery-cards__card-wrapper"
                    key={i}
                    onClick={() => {
                      openPreview(card.image)
                    }}>
                    <div
                      className={`gallery-cards__image-card ${
                        pageContext.title !== "meet-us/office-tour/"
                          ? "gallery-cards__cursor"
                          : ""
                      }`}>
                      <Image useAR={false} publicId={card.image} />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </Columns>
        </Section>
      )}

      {previewImage && (
        <div className="image-preview">
          <div className="close-preview-btn" onClick={closePreview}>
            <FontAwesomeIcon icon={faPlus} />
          </div>
          <Image
            useAR={false}
            publicId={previewImage}
            className="preview-image"
          />
        </div>
      )}

      <Dialog
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className="mbsc-request">
        <h6 style={{ fontSize: "18px" }}>MBSC Information Request</h6>

        {!formLoaded && (
          <div
            style={{
              marginTop: "60px",
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "20px",
              width: "100%"
            }}>
            <div
              className="img-loading"
              style={{ width: "100%", height: "50px" }}></div>
            <div
              className="img-loading"
              style={{ width: "100%", height: "50px" }}></div>
            <div
              className="img-loading"
              style={{ width: "100%", height: "50px" }}></div>
            <div
              className="img-loading"
              style={{ width: "100%", height: "50px" }}></div>
          </div>
        )}
        <iframe
          onLoad={() => setFormLoaded(true)}
          class="seamlessdocsEmbededIframe"
          src="https://secureform.seamlessdocs.com/f/55f2081f70b909c300ed0cca83b4d7f0?embedded=true"
          width="100%"
          height="700px"
          frameborder="0"
          allowtransparency="true"
          sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation allow-popups-to-escape-sandbox"></iframe>
      </Dialog>
    </Layout>
  )
}

export const pageQuery = graphql`
  query MyQuery($title: String!) {
    allAboutThePracticeJson(filter: { title: { eq: $title } }) {
      nodes {
        heading
        desktopHeroImage
        mobileHeroImage
        bodySections {
          type
          string
        }
        imageGallery {
          image
        }
      }
    }
  }
`
export default AboutThePractice
